.container {
    margin-top: 64px; /* Adjust as needed */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .paper {
    text-align: center;
  }
  
  .avatar {
    margin-bottom: 16px; /* Adjust as needed */
  }
  
  .avatar img {
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 8px; /* Adjust as needed */
  }
  
  .body-text {
    font-size: 16px;
  }
  