



.hoodie-image {
  width: 100%; /* Full width within the .main-display container */
  height: 100%;
  margin-bottom: 2rem; /* Space below the main image */
}

/* Thumbnails at the top */
.examples-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  
}
.view-controls {
  position: absolute;
  bottom: 10px; /* Positioned at the bottom of the fullscreen-content */
  left: 50%;
  transform: translateX(-50%); /* Center the controls horizontally */
  display: flex;
  gap: 10px; /* Spacing between buttons */
}
.sidebar {
  flex: 0 0 20%; /* Take up 20% of the container width by default */
  max-width: 240px; /* Maximum width of the sidebar */
  min-width: 150px; /* Minimum width of the sidebar */
  overflow-y: auto; /* Enable vertical scrolling if content is too tall */
  padding: 10px;
  box-sizing: border-box;
}

.example-thumbnail.active img {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.example-thumbnail {
  margin: 35px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow for pop effect */
}
.example-thumbnail img {
  width: 100%; /* Image fills the thumbnail container */
  height: auto;
}
.example-thumbnail:hover {
  transform: scale(1.1); /* Enlarge thumbnails on hover */
}



/* Color swatches */

.color-swatch {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.color-swatch:hover {
  transform: scale(1.1);
}

/* Scale slider and buttons */
.scale-slider {
  width: 10%; /* Take up most of the width on mobile */
  margin: 1rem 0;
}


button, .custom-file-upload {
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin: 10px 5px; /* Slight margin for spacing */
  transition: all 0.3s ease;
}






.side-menu {
  position: fixed; /* Fixed position to the side of the screen */
  top: 0;
  left: 0;
  width: 250px; /* Width of the side menu */
  height: 100%; /* Full height */
  background: #fff; /* Background color of the side menu */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3); /* Shadow for depth */
  transform: translateX(-100%); /* Hide off screen */
  transition: transform 0.3s ease-in-out; /* Smooth transition for opening/closing */
  z-index: 500; /* Example z-index for the side menu */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.side-menu.open {
  transform: translateX(0); /* Move onto the screen when 'open' */
}


.idea {
  height: 2.5rem; /* Example size, adjust as needed */
  width: auto; /* Keep the aspect ratio of the image */
  vertical-align: middle; /* Align it vertically with the text */
  margin-bottom: 1rem;
}
.needle {
  margin-right: "15px";
  height: 2.5rem; /* Example size, adjust as needed */
  width: auto; /* Keep the aspect ratio of the image */
  vertical-align: middle; /* Align it vertically with the text */
  margin-bottom: 1rem;
}


.fullscreen-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px; /* Add some space between the image and buttons */
}
.fullscreen-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%; /* or any other percentage that suits your design */
  max-width: 80%; 
  padding: 2%;
  max-height: 90vh; 
  position: relative;
  /* No max-width or max-height necessary here unless you want to limit it */
}
.fullscreen-content img {
  max-width: 100%; /* image will scale based on its container width */
  max-height: 80vh; /* adjust the height to a reasonable portion of the viewport */
  object-fit: contain; /* this will ensure the image maintains its aspect ratio */
}

.close-btn {
  position: absolute;
  top: 5px; /* adjust position as needed */
  right: 5px; /* adjust position as needed */
  background: none;
  border: none;
  font-size: 1.5rem; /* smaller font size */
  padding: 0.3rem; /* smaller padding */
  cursor: pointer;
  z-index: 10101; /* above the fullscreen-overlay */
  background: #e75151;
  border-radius: 4px;
}


.close-btn:hover {
  background: #e26868;

}


.button-idea {
  padding-left: 2.5rem; 
}
.button-idea:hover{
  background-color: hsl(56, 96%, 78%);
  color: #000000;
}

.download-button:hover{
  background-color: #77c681;
  color: #fff;
}

.custom-file-upload:hover {
  background-color: hsl(204, 41%, 62%);
  color: #fff;
}

.rmv-button:hover {
  background-color: hsl(0, 58%, 53%);
  color: #fff;
}



.MaleHoodie {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align to start if you do not want it to stretch across the screen */
  min-height: 100vh;
  width: 100%; /* Full width */
 background-image: url('./wall.jpg'); /* Path to your image */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */

}
.content-container {
  display: flex; /* Keep using flexbox */
  flex-wrap: wrap; /* Allow wrapping onto multiple lines if needed */
  justify-content: space-between; /* Space out children */
  align-items: flex-start; /* Align children at the top */
  max-width: 100%; /* Max width or your preference for the entire container */
  margin: 0 auto; /* Center the container */
  padding: 20px;
  box-sizing: border-box;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

body, html {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  color: #333;
  font-size: 16px; /* Base font size */
  line-height: 1.6;
}

.main-display {
  position: relative;
  max-width: 100%; /* Adjust this value as needed */
  margin: 20px auto;
}
.hoodie-container {
  flex: 1; /* Takes up remaining space */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the children (the hoodie image) */
  padding: 20px;
}

.hoodie-image {
  width: 100%; /* Full width within the .main-display container */
  height: auto;
  margin-bottom: 2rem; /* Space below the main image */
}

/* Styling for thumbnail images */
.thumbnail-container {
  display: flex;
  justify-content: center; /* Center thumbnails horizontally */
  flex-wrap: wrap; /* Allow wrapping */
  gap: 10px; /* Spacing between thumbnails */
  margin: 20px 0; /* Spacing above and below the container */
}


.thumbnail {
  width: auto; /* Auto width to maintain aspect ratio */
  max-width: 100px; /* Maximum size for thumbnails */
  height: auto; /* Auto height to maintain aspect ratio */
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.thumbnail.active {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Swatch container with a flex wrap */
.color-swatches-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  margin: 20px 10px;
}

.color-swatch {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: border 0.3s ease;
}
.color-swatch:hover {
  transform: scale(1.1);
}
.color-swatch.active {
  border: 2px solid #007bff;
}

/* Slider and buttons */
.scale-slider {
  width: 90%; /* Take up most of the width on mobile */
  margin: 1rem 0;
}
.scale-slider input[type="range"] {
  width: 100%; /* Full width within the .scale-slider container */
}



button, .custom-file-upload {
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin: 10px 5px; /* Slight margin for spacing */
  transition: all 0.3s ease;
}



button:active, .custom-file-upload:active {
  transform: scale(0.98);
}



.color-swatch.white {
  background-color: #ffffff;
  border: 1px solid #ccc; /* Helps visualize the white swatch */
}

.color-swatch.black {
  background-color: #000000;
}

.color-swatch.ash {
  background-color: #e4dde3;
}

.color-swatch.sport_grey {
  background-color: #b6b7ba;
}
.color-swatch.rock {
  background-color: #95938e;
}
.color-swatch.sage {
  background-color: #90adab;
}

.color-swatch.silver {
  background-color: #bec2c4;
}

.color-swatch.Military_Green_Heather
{
  background-color: #495148;
}
.color-swatch.Charcoal
{
  background-color: #3e3d43;
}
.color-swatch.Blush_Pink
{
  background-color: #d9b8af;
}

.color-swatch.Neon_Pink
{
  background-color: #f36c95;
}

.color-swatch.Sunset_Coral
{
  background-color: #e3585d;
}

.color-swatch.Retro_Heather_Coral
{
  background-color: #ff7f85;
}

.color-swatch.Heliconia
{
  background-color: #fa1377;
}

.color-swatch.Orange
{
  background-color: #ed6124;
}

.color-swatch.Heather_Sport_Scarlet_Red
{
  background-color: #b87e8b;
}

.color-swatch.Maroon
{
  background-color: #431c2e;
}

.color-swatch.Red
{
  background-color: #a90e15;
}

.color-swatch.Antique_Cherry_Red
{
  background-color: #95132f;
}

.color-swatch.Sweet_Cream_Heather
{
  background-color: #fffaeb;
}

.color-swatch.light_blue
{
  background-color: #93b1d6;
}

.color-swatch.athletic_yellow
{
  background-color: #dec604;
}

.color-swatch.mauvelous{
  background-color: #c67977!important;

}
.color-swatch.granite_heather{
  background-color: #837d87!important;

}

.color-swatch.true_navy
{
  background-color: #262c44;
}
.color-swatch.Sand
{
  background-color: #d3c6b6;
}.color-swatch.Khaki
{
  background-color: #b5a488;
}.color-swatch.Old_Gold
{
  background-color: #bb9759;
}.color-swatch.Neon_Orange
{
  background-color: #f4732e;
}.color-swatch.Dark_Brown
{
  background-color: #2d1e23;
}.color-swatch.Gold
{
  background-color: #efba00;
}.color-swatch.deep_heather
{
  background-color: #6e6e6e;
}

.color-swatch.dark_grey
{
  background-color: #333;
}

.color-swatch.Neon_Yellow
{
  background-color: #d1df33;
}
.color-swatch.Kiwi
{
  background-color: #71b85e;
}
.color-swatch.Irish_Green
{
  background-color: #03a060;
}

.color-swatch.heather_true_royal
{
  background-color: #576daa!important;
}
.color-swatch.green
{
  background-color: #565b45!important;
}
.color-swatch.Forest_Green
{
  background-color: #132322;
}


.color-swatch.mauve
{
  background-color: #b16e6f!important;
}
.color-swatch.peach
{
  background-color: #f3bba0!important;
}
.color-swatch.Scuba_Blue
{
  background-color: #65cbd3;
}.color-swatch.blue
{
  background-color: #acb8d4!important;
}.color-swatch.Periwinkle_Blue
{
  background-color: #4b57a9;
}.color-swatch.Antique_Sapphire
{
  background-color: #02638d;
}.color-swatch.Vintage_Hth_Blue
{
  background-color: #5d6b8d;
}.color-swatch.Indigo_Blue
{
  background-color: #175a76;
}.color-swatch.Royal
{
  background-color: #1f3481;
}.color-swatch.NAVY
{
  background-color: #272e4b!important;
}

.color-swatch.Violet
{
  background-color: #695294!important;
}.color-swatch.Purple
{
  background-color: #542865;
}.color-swatch.pink
{
  background-color: #edc0cf!important;
}
.color-swatch.Heather_Grey
{
  background-color: #b7b6b8!important;
}

.color-swatch.Creme
{
  background-color: #f9ebd0!important;
}
.color-swatch.Sea_Blue
{
  background-color: #2a3f50!important;
}

