.color-swatch.charcoal
{
  background-color: #3a3a38!important;
}

.color-swatch.jet_black{
background-color : #242424!important;
}

.color-swatch.he_loganberry{
  background-color : #794251!important;
  }


