.container {
    margin-top: 64px; /* Adjust as needed */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .paper {
    text-align: center;
  }
  
  .avatar img {
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 8px; /* Adjust as needed */
  }
  
  .form {
    width: 100%;
    margin-top: 16px;
  }
  
  .form-group {
    margin-bottom: 16px;
  }
  
  .btn {
    cursor: pointer;
    border-radius: 4px;
    padding: 8px 16px;
    margin-top: 16px;
  }
  
  .btn-primary {
    background-color: #3f51b5;
    color: white;
    border: none;
  }
  
  .btn-link {
    background-color: transparent;
    color: #3f51b5;
    border: none;
  }
  
  .loading-bar {
    margin-top: 10px;
    width: 100%;
  }
  
  .progress {
    height: 4px;
    background-color: #3f51b5;
    width: 100%;
  }
  
  /* Modal */
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  