@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

/* Global styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

/* Header styles */


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 80%; /* Adjust width percentage as needed */
  max-width: 500px; /* Set maximum width if desired */
  max-height: 80%; /* Adjust height percentage as needed */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
}



.store-popup-container {
  text-align: center;
}

.store-popup-heading {
  font-size: 20px;
  font-weight: bold;
}
.store-icon {
  margin-top: 10px;
  font-size: 24px;
  margin-left: 15px;
}
.close-modal-button {
  margin-top: 20px;
}

.close-modal-button {
  display: inline-block;
  background-color: #8552f5;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.close-modal-button:hover {
  background-color: #8f64eb;
}

.close-modal-button span {
  display: inline-block;
  transition: transform 0.3s ease;
}

.close-modal-button:hover span {
  transform: scale(1.1);
}

.gif-image {
  max-width: 100%;
  max-height: 100px;
}


/* Category Container */
.category-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Flexible grid */
  gap: 20px;
  padding: 100px;
}

.category-box {
  background-color: #f9f9f9; /* Light grey background for less stark contrast */
  border-radius: 10px; /* Rounded corners for a softer look */
  overflow: hidden;
  position: relative; /* Set the parent to relative positioning */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  display: block;
}
.image-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  color: white; /* White text color */
  opacity: 0; /* Make it invisible by default */
  transition: opacity 0.5s ease;
  font-size: 1.5rem; /* Adjust font size here as needed */
  pointer-events: none; /* Ignore pointer events */
  overflow: hidden; /* Hide overflow */
}

/* Hover effect for the .category-box */
.category-box:hover .image-overlay {
  opacity: 1; /* Make the overlay visible on hover */
}
.category-box:hover {
  transform: translateY(-5px); /* Lift the box slightly on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.category-box img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 200px;
}

/* Text Styles for Links */
a {
  color: #333; /* Dark text for readability on light background */
  text-decoration: none; /* No underline */
}

a:hover {
  text-decoration: underline; /* Underline on hover for clarity */
}


/* How It Works Section Styling */
.how-it-works {
  background: #ffffff; /* Change color if necessary */
  padding: 2rem;
  margin-left: 10%!important;
  margin-top: 5%;

  max-width: 1200px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.how-it-works h2 {
  color: #333; /* Dark text for the heading */
  margin-bottom: 1rem;
  font-size: 2.5rem; /* Larger font size for the heading */
}

.how-it-works p {
  color: #555; /* Slightly lighter text for the paragraph */
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.how-it-works ol {
  list-style: none; /* Removes the default list styling */
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  text-align: left;
}

.how-it-works li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.how-it-works li strong {
  color: #2c3e50; /* A bold color for key terms */
  font-weight: bold;
}

/* If you're using icons or images in the list, you can add styles for them */
.how-it-works li img {
  vertical-align: middle;
  margin-right: 0.5rem;
}

/* Add responsive considerations */

.social-icons {
  display: flex;
  justify-content: center; /* Center icons horizontally */
  align-items: center; /* Center icons vertically */
  gap: 20px; /* Add space between icons */
  margin-bottom: 20px; /* Add margin at the bottom */
  padding: 45px;
}

.social-icon {
  margin-right: 10px;
}

.social-icon img {
  width: 40px; /* Set a specific width */
  height: 40px; /* Set a specific height */
  border-radius: 50%; /* Make the images round */
  transition: transform 0.3s ease-in-out;
}

.social-icon img:hover {
  transform: scale(1.2);
}


.footer-text {
  color: #666666; /* Greish color */
}
