/* Mobile styles */

@media only screen and (max-width: 480px) {
  .thumbnail {
    max-width: 60px!important;
  }
  .sidebar {
    flex: 0 0 100%!important; 
    order: -1!important;
}
.fullscreen-content img {
  max-height: 50vh!important; 
}
.hoodie-image {
  width: 100%!important; 
}

.main-display {
  margin-top: 10px!important;
}

button, .custom-file-upload {
  width: calc(50% - 10px)!important; 
  font-size: 0.9rem!important; 
}
.category-container {
  padding: 20px!important;
}

.category-box {
  display: flex!important;
  flex-direction: column!important;
}

.image-overlay {
  opacity: 1!important;
  background: rgba(0, 0, 0, 0.5)!important; 
}
.category-box img {
  max-height: none!important; 
  height: 100px!important;
  object-fit: contain!important; 
}
.category-box:hover .image-overlay {
  opacity: 1!important;
  background-color: rgba(0, 0, 0, 0.5)!important;
}
.category-box .image-overlay {
  display: block!important; 
  position: static!important;
  background: transparent!important; 
  color: #333!important;
  padding: 10px!important;
  text-align: center!important; 
  opacity: 1!important;
}
}

@media only screen and (max-width: 768px) {
  .App-header {
    padding: 15px!important; 
    width: 110%!important; 
    box-sizing: border-box!important; 
  }

  .App-header h1 {
    font-size: 1.2rem!important;
    letter-spacing: 5px!important; 
    margin-bottom: 10px!important; 
  }

  .App-header img {
    margin-top: 15px!important;
    max-height: 20px!important;
  }
  .thumbnail {
    width: 15%!important; 
  }
  .MaleHoodie {
    width: 100%!important;
    margin: 0 auto!important;
    box-sizing: border-box!important; 
  }
  .main-display {
    max-width: 90%!important;
  }

  .color-swatch {
    width: 35px!important;
    height: 35px!important;
  }

  button, .custom-file-upload {
    width: 100%!important; 
    margin: 10px 0!important;
  }
  .sidebar {
    flex: 0 0 30%!important;
}
.fullscreen-content img {
  max-height: 60vh!important; 
}
.close-btn{
}

.main-display {
  max-width: 90%!important; }

.color-swatch {
  width: 35px!important;
  height: 35px!important; 
}

button, .custom-file-upload {
  width: 100%!important; 
  margin: 10px 0!important;
}
.modal-content {
  width: 70%;
  max-width: none !important;
  max-height: none !important;
  position: fixed; /* Fixed position to keep it centered */
  top: 50%; /* Position from the top */
  left: 50%; /* Position from the left */
  transform: translate(-50%, -50%); /* Translate it by -50% of its own width and height */
}

.category-container {
  padding: 50px 40px 20px!important;
}

.category-box img {
  height: 100%!important;
  max-height: none!important; 
}

.category-box {
  flex-direction: column!important;
}

.image-overlay {
  background-color: rgba(0, 0, 0, 0.5)!important;
  opacity: 1!important;
}
.category-box:hover .image-overlay {
  opacity: 1!important;
  background-color: rgba(0, 0, 0, 0.5)!important;
}

.how-it-works {
  max-width: 100% !important;
  top: 50%; /* Position from the top */
  left: 50%; /* Position from the left */
}


.how-it-works h2 {
  font-size: 2rem!important;
}

.how-it-works p {
  font-size: 1rem!important;
}

.how-it-works ol {
  text-align: center!important;
}

.how-it-works li {
  font-size: 0.9rem!important;
  text-align: left!important;
}

.social-icons {
  justify-content: center!important; 
  margin-top: 20px!important;
  margin-right: 10px!important;
  margin-bottom: 20px!important; }

.social-icon img {
  width: 30px!important;
  height: 30px!important;
}

.button-idea{
  width: 50%!important;
}
.fullscreen-overlay {
  align-items: flex-start;
  padding-top: 50px; /* Add some padding at the top for better spacing */
}
}

/* Tablet styles */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .App-header h1 {
    font-size: 2rem!important;
    letter-spacing: 8px!important; 
  }
}

/* Default styles */
.App-header {
  background-color: #212529;
  color: #f8f8f8;
  padding: 20px;
  text-align: center;
  width: 100%; 
  box-sizing: border-box; 
}

.App-header img {
  max-width: 80%;
  max-height: 80px;
  height: auto;
}

.App-header h1 {
  font-size: calc(1.5rem + 1vw); 
  font-weight: 300;
  letter-spacing: 10px; }

  .fullscreen-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    flex-direction: row;
    z-index: 2000; /* Make sure this is higher than the side-menu's z-index */
  }